import * as React from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import * as style from './team.module.css'

const Team = () => {
  return (
    <Layout pageTitle="Spiked">
      <Helmet>
        <meta name="description" content="The team who created Spiked Film" />
        <title>Team | Spiked Film</title>
        <body className="bg-img team-bg-img" />
      </Helmet>
      <title>Team</title>

      <div className={style.galleryContainer}>
        <div className={style.teamMember}>
          <StaticImage
            className={style.teamImage}
            alt="Photo of Juan Martinez Vera"
            src="../images/team/joshua.jpg"
          />
          <div className={style.name}>Juan Martinez Vera</div>
          <div className={style.job}>Writer/Director/Producer</div>
        </div>
        <div className={style.teamMember}>
          <StaticImage
            className={style.teamImage}
            alt="Photo of Per Melita"
            src="../images/team/per.jpg"
          />
          <div className={style.name}>Per Melita</div>
          <div className={style.job}>Producer</div>
        </div>
        <div className={style.teamMember}>
          <StaticImage
            className={style.teamImage}
            alt="Photo of Joe Soldwedel"
            src="../images/team/joe.jpg"
          />
          <div className={style.name}>Per Melita</div>
          <div className={style.job}>Executive Producer</div>
        </div>
        <div className={style.teamMember}>
          <StaticImage
            className={style.teamImage}
            alt="Photo of Brett Soldwedel"
            src="../images/team/brett.jpg"
          />
          <div className={style.name}>Brett Soldwedel</div>
          <div className={style.job}>Executive Producer</div>
        </div>
        <div className={style.teamMember}>
          <StaticImage
            className={style.teamImage}
            alt="Photo of Darren Clark"
            src="../images/team/Darren-Clark.jpg"
          />
          <div className={style.name}>Darren Clark</div>
          <div className={style.job}>Production Designer</div>
        </div>
        <div className={style.teamMember}>
          <StaticImage
            className={style.teamImage}
            alt="Photo of Kathryn J. Schubert"
            src="../images/team/Kathryn-J.jpg"
          />
          <div className={style.name}>Kathryn J. Schubert</div>
          <div className={style.job}>Editor</div>
        </div>
        <div className={style.teamMember}>
          <StaticImage
            className={style.teamImage}
            alt="Photo of Kubilay Uner"
            src="../images/team/kubilay-uner.jpg"
          />
          <div className={style.name}>Kubilay Uner</div>
          <div className={style.job}>Composer</div>
        </div>
        <div className={style.teamMember}>
          <StaticImage
            className={style.teamImage}
            alt="Photo of Will Jobe"
            src="../images/team/will-jobe.jpg"
          />
          <div className={style.name}>Will Jobe</div>
          <div className={style.job}>Director of Photography</div>
        </div>
        <div className={style.teamMember}>
          <StaticImage
            className={style.teamImage}
            alt="Photo of Justin Kreinbrink"
            src="../images/team/justin-kreinbrink.jpg"
          />
          <div className={style.name}>Justin Kreinbrink</div>
          <div className={style.job}>Co-Producer</div>
        </div>
        <div className={style.teamMember}>
          <StaticImage
            className={style.teamImage}
            alt="Photo of Hernan Toro"
            src="../images/team/hernan-toro.jpg"
          />
          <div className={style.name}>Hernan Toro</div>
          <div className={style.job}>Director of Photography</div>
        </div>
        <div className={style.teamMember}>
          <StaticImage
            className={style.teamImage}
            alt="Photo of Paula Rogers"
            src="../images/team/paula-rogers.jpg"
          />
          <div className={style.name}>Paula Rogers</div>
          <div className={style.job}>Costume Designer</div>
        </div>
      </div>
    </Layout>
  )
}

export default Team
